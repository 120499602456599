<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h2 class="purple bold">Editar registro</h2>
      </b-col>
      <b-col class="text-right"> </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <StandardInput
          v-if="student"
          v-model="student.User.name"
          type="text"
          label="Aluno"
          disabled
        ></StandardInput
      ></b-col>
      <b-col md="3">
        <b-form-group class="calendar">
          <label>Data</label>
          <Datepicker
            v-model="dateAux"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @input="dateChanged = true"
          >
          </Datepicker>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <StandardInput
          v-model="timeAux"
          type="time"
          name="designation"
          label="Hora"
        >
        </StandardInput>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <StandardInput
          v-if="attendance.token_user && attendance.token_user.name"
          v-model="attendance.token_user.name"
          type="text"
          label="Registro feito em"
          disabled
        ></StandardInput
      ></b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-form-group class="group-radio">
          <label for="label" class="mb-4">Ação</label>
          <div class="radio-inline">
            <b-form-radio
              v-model="attendance.type"
              name="options"
              value="presence"
            >
              Presença
            </b-form-radio>
            <b-form-radio
              v-model="attendance.type"
              name="options"
              value="regular_leave"
            >
              Saída regular
            </b-form-radio>
            <b-form-radio
              v-model="attendance.type"
              name="options"
              value="absence"
            >
              Falta
            </b-form-radio>
            <b-form-radio v-model="attendance.type" name="options" value="late">
              Chegada tardia
            </b-form-radio>
            <b-form-radio
              v-model="attendance.type"
              name="options"
              value="antecipated"
            >
              Saída antecipada
            </b-form-radio>
          </div>
        </b-form-group>
      </b-col>
      <div class="w-100 mt-5"></div>
    </b-row>

    <b-row>
      <div class="w-100 mt-5"></div>
      <b-col md="2">
        <b-button
          class="large blue outline"
          :to="{ name: redirectToCorrectUrl('faltas-presencas') }"
          >Voltar</b-button
        >
      </b-col>
      <b-col md="2">
        <b-button @click="update" class="large blue">Atualizar</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DataService from "@/services/students.service";
import * as lang from "vuejs-datepicker/src/locale";
import StandardInput from "@/components/inputs/StandardInput.vue";
import moment from "moment-timezone";
import httpRda from "../../../http-rda";
import Datepicker from "vuejs-datepicker";
import { mapGetters } from "vuex";

export default {
  name: "faltas-presencas-editar",
  components: {
    StandardInput,
    Datepicker,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
  },

  data() {
    return {
      student: null,
      attendance: null,

      dateAux: moment().format("YYYY-MM-DD").toString(),
      timeAux: moment().format("HH:mm").toString(),

      dateChanged: false,

      language: "ptBR",
      languages: lang,

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Faltas e presenças",
          active: true,
        },
      ],
    };
  },

  methods: {
    redirectToCorrectUrl(url) {
      if (
        this.currentUser.role &&
        this.currentUser.role.includes("attendance_manager")
      ) {
        return "registro-" + url;
      } else {
        return url;
      }
    },

    isValid() {
      return (
        this.attendance.date &&
        this.attendance.type &&
        typeof this.attendance.delay === "string" &&
        this.attendance.delay.length === 5
      );
    },

    update() {
      const currentTzOffset = Math.abs(moment().utcOffset() / 60);

      let customDate;

      if (this.dateChanged) {
        customDate = moment(this.dateAux)
          .add(currentTzOffset > 0 ? 1 : 0, "day")
          .format("YYYY-MM-DD");

        this.dateAux = moment
          .tz(
            customDate + " " + this.timeAux,
            "YYYY-MM-DD HH:mm",
            "America/Sao_Paulo"
          )
          .utc();
      } else {
        customDate = this.dateAux + " " + this.timeAux;

        this.dateAux = moment
          .tz(
            this.dateAux + " " + this.timeAux,
            "YYYY-MM-DD HH:mm",
            "America/Sao_Paulo"
          )
          .utc();
      }

      const data = {
        student_id: this.student.id,
        date: this.dateAux,
        type: this.attendance.type,
      };

      DataService.updateStudentAttendance(this.attendance.id, data)
        .then((response) => {
          this.$router.push({
            name: this.redirectToCorrectUrl("faltas-presencas"),
          });
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Registro atualizado com sucesso!",
          });

          return response.data;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage.error,
            footer: "",
          });

          return error.response;
        });
    },
    async getSelected(id) {
      this.attendance = await httpRda
        .get(`/attendances/${id}`)
        .then((response) => response.data.attendance);

      this.dateAux = this.attendance.date.split(" ")[0];
      this.dateAux = moment(this.dateAux, "DD/MM/YYYY").format("YYYY-MM-DD");

      this.timeAux = this.attendance.date.split(" ")[1];

      this.student = await httpRda
        .get(`students/${this.attendance.student_id}`)
        .then((response) => response.data.student);
    },
  },

  created() {
    this.getSelected(this.$route.params.id, this.$route.params.attendance_id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.home {
  height: 100%;
}

.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
</style>
